import React from 'react'

import './info.css'

const Info2 = () => {
  return (
    <div className='componentFirst info2Ani'>
      <div className='componentSecond'>
        <div className='infoSecond'>
          <div className='infoTitle' style={{ textAlign: 'center' }}>
            HELHETSPERSPEKTIV
          </div>
          <div className='infoSubTitle infoSubTitleSecond'>Teknik som verktyg, inte fokus</div>
          <div className='infoTextSecond'>
            Vi arbetar kundfokuserat med innovation och din utveckling som högsta prioritet. Tekniken skall anpassas efter dig och inte tvärtom.
          </div>

          <div className='info2'>
            <div className='info2Left'>
              <div className='info2IconContainer'>
                <div className='info2Icon'>
                  <img src='./images/bs.png' alt='EVENU' className='info2IMGElement' />
                </div>
                <div className='info2Text'>Kunder berättar:</div>
              </div>
              <div className='info2Content'>
              Vi använder STORM som spindeln i nätet för telefonirelaterade ärenden. Det skräddarsyddes för våra behov och hjälper oss varje dag att hålla kundupplevelsen, kvalitén och överblicken på högsta nivå. Inget faller mellan stolarna och över 23 000 ärenden senare är STORM ett av våra viktigaste verktyg.
              </div>
              <div className='info2Links'>
                <div className='info2Item'>
                  <div className='info2ItemDot' />
                  <div className='info2ItemText'>Maria, IT-/Dataservice Borås Stad</div>
                </div>
                
                
              </div>
              <div className='info2IconContainer'>
                <div className='info2Icon'>
                  <img src='./images/stt.png' alt='EVENU' className='info2IMGElement' />
                </div>
                <div className='info2Text'>Kunder berättar:</div>
              </div>
              <div className='info2Content'>
              Med EVENUs stöd och STORM som verksamhetssystem kan vi fortsätta utvecklas utan växtvärk. Vi arbetar effektivare, med högre kvalitet och ger våra kunder en bättre upplevelse.
              </div>
              <div className='info2Links'>
                <div className='info2Item'>
                  <div className='info2ItemDot' />
                  <div className='info2ItemText'>Niclas, Söderkulla Tung Trafik</div>
                </div>
              </div>
            </div>
            <div className='info2Right'>
              <div className='info2Box'>
                <div className='infoTitle boxTitle'>EVENU - DEN DIGITALA RESAN</div>
                <div className='info2NumberContainer'>
                  <div className='info2Number'>01. </div>
                  <div className='info2NumberText'>Förstå</div>
                </div>
                <div className='info2Content' style={{ textAlign: 'left' }}>
                  Förståelse för nuläge, behov och hur tekniska lösningar på bästa sätt kan stötta dig och din verksamhet.
                </div>
                <div className='info2NumberContainer'>
                  <div className='info2Number'>02. </div>
                  <div className='info2NumberText'>Utveckla</div>
                </div>
                <div className='info2Content' style={{ textAlign: 'left' }}>
                  Ta nästa steg med behovsanpassade lösningar som stärker och lyfter i vardagen.
                  Målet är alltid en bättre upplevelse för både dig och dina kunder.
                </div>
                <div className='info2NumberContainer'>
                  <div className='info2Number'>03. </div>
                  <div className='info2NumberText'>Förvalta</div>
                </div>
                <div className='info2Content' style={{ textAlign: 'left' }}>
                  Säkerställ digital trygghet där tekniska system fungerar optimalt och växer med dig och din verksamhet.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Info2
