import React from 'react'

import './info.css'

const Info = () => {
  return (
    <div className='componentFirst introAni'>
      <div className='componentSecond'>
        <div className='infoWrapper'>
          <div className='info'>
            <div className='infoLeft'>
              <div className='infoIMG'>
                <img src='./images/journey.png' alt='EVENU' className='infoIMGElement' />
              </div>
            </div>
            <div className='infoRight'>
              <div className='infoTitleContainer'>
                <div className='infoTitleLine' />
                <div className='infoTitle'>KONSULTING - IT som är rätt</div>
              </div>
              <div className='infoSubTitle'>Teknik och rådgivning som för din verksamhet framåt</div>
              <div className='infoText'>
                <div className='infoTextFixed'>
                  <p>EVENU vägleder dig på din digitala resa och säkerställer att tekniken skapar möjligheter och hjälper dig att nå dina mål.</p>
                  <p>Vi arbetar som helhetsleverantör eller som en del i ett större sammanhang.</p>
                  <p>Med lång erfarenhet och ett stort nätverk av partners som alla är ledande inom sitt område, levererar vi tekniska lösningar, rådgivning, analys och projektledning.</p>
                </div>
              </div>
            </div>
          </div>

          <div className='info'>
            <div className='infoRight'>
              <div className='infoTitleContainer'>
                <div className='infoTitleLine' />
                <div className='infoTitle'>VERKSAMHETSSYSTEM - ANPASSAT FÖR DIG</div>
              </div>
              <div className='infoSubTitle'>STORM - skräddarsytt systemstöd för din verksamhet</div>
              <div className='infoText'>
                <div className='infoTextFixed'>
                  <p>Vi har alla byggklossarna och kombinerar dem till något som passar just dina behov.</p>
                  <p>STORM är ett flexibelt och mångsidigt verksamhetssystem som alltid anpassas för sin uppgift.</p>
                  <p>Med kort startsträcka stöttar STORM dig i vardagen och automatiserar, integrerar och säkerställer kvalitet. Snabbt, enkelt, säkert och alltid tillgängligt.</p>
                </div>
              </div>
            </div>
            <div className='infoLeft'>
              <div className='infoIMG'>
                <img src='./images/storm.png' alt='STORM' className='infoIMGElement' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Info
