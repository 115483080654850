import React from 'react'

import Navbar from '../../components/navbar/Navbar'

import mainBC from '../../icons/mainBC.png'
import mainBCMobile from '../../icons/mainBCMobile.png'

import './intro.css'

const Intro = () => {
  const handleAbout = () => {
    let about = document.getElementsByClassName('introAni')
    window.scroll({
      behavior: 'smooth',
      top: about[0].offsetTop - 50,
    })
  }

  const handleContact = () => {
    let about = document.getElementsByClassName('footerBC')
    window.scroll({
      behavior: 'smooth',
      top: about[0].offsetTop - 50,
    })
  }
  return (
    <div className='componentFirst introMain'>
      <div className='introNav'>
        <Navbar />
      </div>
      <img src={mainBC} alt='' className='introBC123' />
      <img src={mainBCMobile} alt='' className='introBC123Mobile' />

      <div className='intro'>
        <div className='introContainer'>
          <div className='introTitle'>EVENU</div>
          <div className='introText'>Your digital way forward</div>
          <div className='introButtons'>
            <div className='introButtonOUT' onClick={handleAbout}>
              Mer om EVENU
            </div>
            <div className='introButtonIN' onClick={handleContact}>
              Kontakt
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
