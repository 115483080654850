import React, { useEffect, useState } from 'react'

//import { ReactComponent as MobileMenu } from '../../icons/MobileMenu.svg'
import { ReactComponent as Close } from '../../icons/Close.svg'
//import { ReactComponent as Youtube } from '../../icons/Youtube.svg'
//import { ReactComponent as Twitter } from '../../icons/Twitter.svg'

// import { ReactComponent as Logo } from '../../icons/Logo.png'

import './navbar.css'
const Navbar = () => {
  const [Mobile, setMobile] = useState(false)
  useEffect(() => {
    WindowChange()
  }, [])

  const HandleMobileMenu = () => {
    setMobile(!Mobile)
  }

  const WindowChange = () => {
    if (window.innerWidth > 1050) {
      setMobile(false)
    }
  }

  window.addEventListener('resize', WindowChange)
  return (
    <div className='navbar'>
      <div className='navbarMobile'>
        <div className='navbarCenterIcon'>
          <div className='navbarMobileTopRight '>
            <img src="./images/Logo.png" className='navbarLogo' />
          </div>
        </div>
      </div>
      <div className='navbarMobileButton'>
        {/** <MobileMenu className={Mobile ? 'Mobile' : 'Mobile'} onClick={HandleMobileMenu} />*/}
        <div className={Mobile ? 'navbarMobileContainerActive' : 'navbarMobileContainer'}>
          <div className={Mobile ? 'navbarMenu active' : 'navbarMenu'}>
            <div className='navbarMenuContainer'>
              <div className='navbarMobileTop'>
                <div className='navbarMobileTopRight menuOpen'>
                  <img src="./images/Logo.png" className='navbarLogo' />
                </div>
                <div className='navbarMobileTopLeft'>
                  <Close className='CloseIcon' onClick={HandleMobileMenu} />
                </div>
              </div>
              <div className='navbarMobileMain'>
                {/**
                <div className='navbarCenterLink opacity7'>Home</div>
                <div className='navbarCenterLink opacity7'>Product</div>
                <div className='navbarCenterLink opacity7'>Join Us</div>
                <div className='navbarCenterLink opacity7'>Contact</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='navbarContainer'>
        <div className='navbarLeft'>
          <div className='navbarLeftLogo'>
            <div className='navbarLogoLeftTitle'>
              <img src="./images/Logo.png" className='navbarLogo' />
            </div>
          </div>
        </div>
        <div className='navbarCenter'>
          {/*
          <div className='navbarCenterItem'>Home</div>
          <div className='navbarCenterItem'>Product</div>
          <div className='navbarCenterItem'>Join Us</div>
          <div className='navbarCenterItem'>Contact</div>
        */}
        </div>
        <div className='navbarRight'>
          {/** 
          <div className='navbarRightItem'>
            <Youtube />
          </div>
          <div className='navbarRightItem'>
            <Twitter />
          </div>
          */}
        </div>
      </div>
    </div>
  )
}

export default Navbar
