import React from 'react'
import Animation from '../../components/animation/Animation'
import Info from '../../components/info/Info'
import Info2 from '../../components/info/Info2'
import Intro from '../../components/intro/Intro'

import './main.css'

const Main = () => {
  return (
    <div className='main'>
      <Intro />
      <Info />
      <Info2 />
      <Animation />
    </div>
  )
}

export default Main
