import React from 'react'
// import { ReactComponent as Logo } from '../../icons/Logo.svg'
import { ReactComponent as Phone } from '../../icons/Phone.svg'
import { ReactComponent as Mail } from '../../icons/Mail.svg'
import { ReactComponent as Address } from '../../icons/Address.svg'

import './footer.css'
const Footer = () => {
  return (
    <>
      <div className='footerBC componentFirst'>
        <div className='footerMain componentSecond'>
          <div className='footerMainLeft'>
            <div className='footerMainLeftLogo'>
              <div className='footerLogoContent'>
                <div className='footerLogoTitle'>
                  <img src="./images/Logo.png" className='footerlogo' />
                </div>
              </div>
            </div>
          </div>
          <div className='footerMainRight'>
            {/**
             * 
             <div className='footerMainRightItem'>
              
              <div className='footerMainRightItemText' onClick={handleFB}>
                Instagram
              </div>
              <div className='footerMainRightItemText' onClick={handleFB}>
                Facebook
              </div>
              <div className='footerMainRightItemText' onClick={handleFB}>
                Youtube
              </div>
            </div>
            <div className='footerMainRightItem'>
              <div className='NoLink'>
                <div className='footerMainRightItemText'>Privacy Policy</div>
              </div>
              <div className='NoLink'>
                <div className='footerMainRightItemText'>Cookie Policy</div>
              </div>
            </div>
            */}
            <div className='footerMainRightItem'>
              <div className='footerMainRightItemTextNo footerLogoInfo'>EVENU Sweden AB</div>
              <div className='footerMainRightItemText'>
                <div className='footerIconContainer'>
                  <Address className='footerIcon' />
                </div>
                Segloravägen 6 <br />
                504 64 Borås
              </div>
              <div className='footerMainRightItemText'>
                <div className='footerIconContainer'>
                  <Phone className='footerIcon' />
                </div>
                <div className='footerIconText'>010-88 99 320</div>
              </div>
              <div className='footerMainRightItemText'>
                <div className='footerIconContainer'>
                  <Mail className='footerIcon' />
                </div>
                info@evenu.se
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footerBC componentFirst'>
        <div className='footerMain2 componentSecond'>
          <div className='footerBottom'>
            <div className='footerBottomText'></div>
            <div className='footerBottomLeft'>
              <div className='footerBottomText'>© 2022 EVENU Sweden AB</div>
            </div>
            <div className='footerBottomCenter'></div>
            <div className='footerBottomRight'>
              <div className='footerBottomText'></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
