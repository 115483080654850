import React from 'react'

import Main from './pages/main/Main'
import Footer from './components/footer/Footer'

import { ReactComponent as ArrowUp } from './icons/Arrow.svg'

const App = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <>
      <Main />
      <Footer />
      <div className='GoToTop' onClick={handleScrollToTop}>
        <ArrowUp />
      </div>
    </>
  )
}

export default App
