import React from 'react'
import './animation.css'
const Animation = () => {
  window.onscroll = function () {
    scrollAnimation()
  }

  const scrollAnimation = () => {
    let offset = 300
    let WindowsPosition = window.pageYOffset
    let windowBottomPosition = WindowsPosition + window.outerHeight
    let introAni = document.getElementsByClassName('introAni')
    let info2Ani = document.getElementsByClassName('info2Ani')

    let introAniPosition = windowBottomPosition - introAni[0].offsetTop - offset

    //opacity
    if (introAni[0].offsetTop < windowBottomPosition) {
      if (introAniPosition < 1) {
        introAni[0].style.opacity = introAniPosition
        introAni[0].style.transform = 'translateY(20%)'
      } else {
        introAni[0].style.opacity = 1
        introAni[0].style.transform = 'translateY(0%)'
      }
    } else {
      introAni[0].style.opacity = 0
    }
    let info2AniPosition = windowBottomPosition - info2Ani[0].offsetTop - offset

    //opacity
    if (info2Ani[0].offsetTop < windowBottomPosition) {
      if (info2AniPosition < 1) {
        info2Ani[0].style.opacity = info2AniPosition
        info2Ani[0].style.transform = 'translateY(20%)'
      } else {
        info2Ani[0].style.opacity = 1
        info2Ani[0].style.transform = 'translateY(0%)'
      }
    } else {
      info2Ani[0].style.opacity = 0
    }
  }
  return <></>
}

export default Animation
